import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: () => import('./view/home'),
  //   meta: {
  //     title: '首页'
  //   }
  // },
  {
    path: '/',
    name: 'siwow',
    component: () => import('./view/siwow'),
    meta: {
      title: ''
    }
  },
];
// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({ mode: 'hash',routes });

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export {
  router
};
